.App {
  text-align: center;
  background-image: url('/public/calpe_cliff_panorama.JPG');
  /* background-position: 'center'; */
  background-size: cover;
  /* background-repeat: no-repeat; */
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}

.we-sell-travel-logo {
  padding: 5px 5px 5px 5px;
  pointer-events: none;
}


.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
  background-color: #282c34;
}

#mc_embed_signup{
  clear:left; 
  font:14px Helvetica,Arial,sans-serif;
}

.Header-text {
  display: flex;
  align-items: center;
  color:#ffffff;
  background-color:rgba(139, 139, 139, 0.5);
}
